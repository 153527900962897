.buyChipsWindow .balance span {
    color: #ffe555;
}

.buyChipsWindow .slider {
    width: 90%;
    height: 50px;
    background-color: #092529;
    border: 1px solid rgba(255, 229, 85, 0.4);
    border-radius: 3px;
    margin-left: 2%;
    margin-bottom: 5px;

}

.buyChipsWindow #sliderBar {
    width: 200px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
}

input[type="range"] {
    appearance: slider-vertical;
    -webkit-appearance: slider-vertical;
    /* writing-mode: vertical-lr;
    direction: rtl; */
}

.buyChipsWindow #textInput {
    width: 80px;
    margin-left: 10px;
    height: 20px;
}

.buyChipsWindow .footer {
    width: 100%;
    height: 45px;
    background-color: #092529;
    border-radius: 0px 0px 5px 5px;
    display: flex;
}

.buyChipsWindow .footer button {
    width: 80px;
    height: 30px;
    background-color: #fbe35d;
    outline: none;
    border: none;
    border-radius: 7px 0px 7px 0px;
    float: right;
    margin-right: 10px;
    margin-top: 5px;
    margin-left: 100px;
}

.buyChipsWindow .footer button:hover {
    background-color: #ffe555;
}

.buyChipsWindow .footer .buyChipsTimer {
    width: 150px;
    height: 20px;
    color: silver;
    float: left;
    margin-left: 10px;
    margin-top: 10px;
}

.buyChipsWindow .balance p {
    color: #ffe555;
    text-align: center;
}

.buyNew {
    width: 100%;
    float: right;
    display: flex;
    flex-direction: column;
    padding: 0px 30px 0px 10px;
}

.buyBalance {
    display: flex;
    flex-direction: row;
}

.minMaxBalance {
    display: flex;
    flex-direction: row;
}

.minMaxBalance span {
    margin: 3px 0px 0px 0px;
}

.settingsSpan {
    display: flex;
    align-items: center;
    justify-content: center;
}

.settingsSpan img {
    margin: 0px 5px 0px 0px;
}


.slider-wrapper {
    --slider-height: 0.3125em;
    --slider-thumb-size: 1.75em;
    --slider-progress: 5;
    border-radius: 50px;
    width: 7em;
    transform: rotate(270deg);
    height: 10px;
    width: 135px;
    position: absolute;
    top: 75px;
    left: 10px;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: none;
    border-radius: 5px;
    border: 1px solid #000000;
}

.slider-wrapper.unlocked {
    width: 8em;
}

.slider-track {
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: inherit;
    width: 100%;
    height: 10px;
    background-image: linear-gradient(to right, transparent 0% calc(var(--slider-progress) * 1%), #323232 calc(var(--slider-progress) * 1%) 100%), linear-gradient(to right, #62ad1d, #fbbd1e, #fb6e1e, #e71a1a, #200);
    box-shadow: inset 0 2px 4px #000, inset 0 -2px 4px #000, 0 2px 4px #000;
    transition: background-size 0.2s;
    border: 1;
}

.slider-wrapper.unlocked>.slider-track {
    background-size: auto, 100% 100%;
}

.slider-input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translate(-50%);
    border-radius: inherit;
    width: calc(100% + var(--slider-thumb-size));
    height: 100%;
    opacity: 0;
}

.slider-input::-webkit-slider-runnable-track {
    height: 100%;
}


.slider-thumb img {
    transform: rotate(270deg);
    width: 30px;
    margin-bottom: 50%;
}

.slider-thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: calc(var(--slider-progress) / 100 * (100% - var(--slider-height)) + var(--slider-height) / 2);
    transform: translateX(-50%);
    height: var(--slider-thumb-size);
    transition: transform 0.2s;
}

.slider-input:active+.slider-thumb {
    transform: translateX(-50%) scale(0.86);
}

.backRange {
    margin-left: 50px;
    z-index: 9;
    border: 1px solid red;
    opacity: 0;
    height: 145px;
    position: inherit;
    width: 25px !IMPORTANT;
    margin-top: -35px;
    left: 0;
    top: 30px;
    right: 0;
    opacity: 0;
    bottom: 0;


    margin-left: 50px !important;
    z-index: 9 !important;
    border: 1px solid red !important;
    opacity: 0 !important;
    height: 145px !important;
    position: inherit;
    width: 25px !important;
    margin-top: -35px !important;
    left: 0 !important;
    top: 30px !important;
    right: 0 !important;
    bottom: 0 !important;
}

.errorMsgDiv {
    text-align: center;
}

.errorMsgDiv span {
    margin: auto;
    color: red;
    font-size: 13px;
    font-weight: 500;
}

.inputValue:focus-visible {
    outline: none;
}

.inputValue {
    padding: 12px;
    border-radius: 5px;
    /* background: #25316A; */
    background: #1e1e1ecc;
    color: white;
    border: 1px solid #6C6B6B;
    /* box-shadow: 2px 2px 9px 2px #03050c; */
    width: 30%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center;
    box-shadow: inset 1px 0px 10px 0.5px #000;
}

input.inputValue::-webkit-outer-spin-button,
input.inputValue::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* .exchangeBtns button {
    width: 25%;
} */

.exchangeBtns {
    position: relative;
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 10px;
}



.exChangeDiv {
    padding: 0px 10px 0px 10px;
}

.exChangeDiv table {
    /* font-family: arial, sans-serif; */
    border-collapse: collapse;
    width: 100%;
}

.exChangeDiv table tbody tr td input {
    width: 100px;
}

.exChangeDiv table tbody tr td:last-child {
    display: flex;
    flex-direction: column;
}

.exChangeDiv table tr td span:last-child {
    margin: 5px 0px;
}

/* .exChangeDiv table tr td span {
    width: 35%;
    position: relative;
    float: left;
    overflow: hidden;
    margin: 0px 3px 0px 0px;
} */



/* .exCangeDiv table tr td:nth-last-child(3) {
    display: flex;
    flex-wrap: wrap;
} */

.exChangeDiv table tr td {
    border: 1px solid grey;
    text-wrap: wrap;
    /* text-wrap: nowrap; */
    padding: 3px 5px;
    text-align: center;
}

.exChangeDiv table tr {
    border: 1px solid grey;
    text-wrap: nowrap;
    padding: 3px;
    text-align: center;
}


.custom-slider {
    -webkit-appearance: none;
    width: 300px;
    height: 30px;
    /* Increased to accommodate thumb without overflow */
    /* background: #ddd; */
    border-radius: 5px;
    margin: 0 10px;
    outline: 5px solid #000000c0;
    /* Outline around the range bar */
    padding: 0;
    /* Remove default padding */
    position: relative;
    box-sizing: border-box;
    z-index: 0;
    /* background: linear-gradient(to right, rgba(254,214,38,0.7595413165266106) 41%, rgba(179,71,0,0.7567401960784313) 51%); */
}

.custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 3rem;
    /* Width of the thumb */
    height: 3rem;
    /* Adjust based on your image size */
    background: url('../../../assets/images/table/slider_button_thumb1.png') no-repeat center center;
    background-size: cover;
    cursor: pointer;
    border: none;
    /* Remove default border */
    border-radius: 50%;
    /* Optional: if your image is circular */
    position: relative;
    /* top: -1.5rem; */
    left: 0px;
    /* Adjust to align thumb vertically */
    z-index: 1;
    box-sizing: border-box;
}

.custom-slider::-moz-range-thumb {  
    -moz-appearance: none;
    width: 3rem;
    /* Width of the thumb */
    height: 3rem;
    /* Adjust based on your image size */
    background: url('../../../assets/images/table/slider_button_thumb1.png') no-repeat center center;
    background-size: cover;
    cursor: pointer;
    border: none;
    /* Remove default border */
    border-radius: 50%;
    /* Optional: if your image is circular */
    position: relative;
    /* top: -1.5rem; */
    left: 0px;
    /* Adjust to align thumb vertically */
    z-index: 1;
    box-sizing: border-box
}


.custom-slider::-webkit-slider-thumb,
.custom-slider::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 3rem;
    height: 3rem;
    background: url('../../../assets/images/table/slider_button_thumb1.png') no-repeat center center;
    background-size: cover;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    box-sizing: border-box; /* Ensures correct sizing */
}


@media (max-width: 768px) {
    .custom-slider::-webkit-slider-thumb {
        top: -1rem;
        width: 2.5rem;
        /* Adjust width of the thumb */
        height: 2.5rem;
        /* Adjust height of the thumb */
    }
}


.value-display {
    font-size: 18px;
    margin-left: 10px;
}