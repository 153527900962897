
.myBuddies .footjoin:hover{
    background-color: #fbe111;
}
.myBuddies .footclose:hover{
    background-color: #fbe111;
}
.myBuddies #table {
    /* font-family: Arial, Helvetica, sans-serif; */
    border-collapse: collapse;
    width: 100%;
    border:1px solid #091d29; 
     border-collapse:separate;  
    border-spacing:0 3px; 
    font-size: 13px;
  }
  
  .myBuddies  #table td, #table th {
    border: 1px solid rgba(0, 0, 0, 0.1);
    
  }
  .myBuddies  #table td{
    width:48.98%;
      color: #fbe555;
  }
  .myBuddies  #table tr:nth-child(even){background-color: #142f33;}
  .myBuddies  #table tr:nth-child(odd){background-color:  #0a363c;}
  
  .myBuddies  #table tr:hover {
    -ms-transform: scale(1.015);
    -webkit-transform: scale(1.015); 
    transform: scale(1.015); 
  }
  .myBuddies  #table tr {
    width: 96%;
    margin-left: 2%;
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    list-style:none;
    border:solid 1px rgb(255, 229, 85,.2);
    border-radius: 2px;

  }
  