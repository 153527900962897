  .playerlevelInfo .vipjsondata{
    position: relative;
    bottom:42px;
    color: #fbe555;
    font-size: 14px;
}

 
  

 
