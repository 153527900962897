.t-info {
  max-width: 500px;
  width: calc(100vw - 20px);
  height: calc(100vh - 200px);
  margin: 10px;
  position: fixed;
  z-index: 1200;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 5px;
  color: #fff;
  overflow: hidden;
  background: var(--clr_3);
}

.t-info .head {
  padding: 10px;
  background: linear-gradient(#131d50, #040610);
  /* background: var(--clr_3); */
  margin: 0;
  color: #fff;
  font-size: 14px;
  /* font-style: italic; */
  font-weight: bold;
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: italic;
  /* border-bottom: var(--clr_ff); */
}

.t-info .table_cont {
  height: calc(100% - 270px);
  width: 100%;
  overflow-y: scroll;
}

.t-info .table_cont .table {
  width: 100%;
  height: 100%;
  font-size: 13px;
  border-left: 1px solid var(--bdr);
  border-bottom: 1px solid var(--bdr);
  color: #cbcbcb;
  border-collapse: collapse;
}

.t-info .table_cont .table thead {
  position: sticky;
  top: 0;
}

.t-info .table_cont .table tr th {
  height: 20px;
  text-align: center;
  background-color: var(--clr_3);
  color: var(--clr_4);
  border-top: 1px solid var(--bdr);
  position: sticky;
  top: 0px;
}

.t-info .table_cont .table tr td {
  padding: 5px;
  border-right: 1px solid var(--bdr);
  border-top: solid 1px var(--bdr);
  font-weight: 300;
}

.t-info .playersCount {
  padding: 0 10px;
  bottom: 30px;
}


.t-info .table_cont::-webkit-scrollbar {
  width: 0px;
}

.t-info .table_cont::-webkit-scrollbar-thumb {
  background-color: #00000000;
  outline: 1px solid #00000000;
}

.test_row td:last-child {
  text-align: end;
}