.TIPwaitListTable2{
    width:calc(100% - 4px);
    height:calc(100% - 2px);
    border-left: 2px solid rgba(255, 229, 85, 0.3);
    border-right: 2px solid rgba(255, 229, 85, 0.3);
    border-bottom: 2px solid rgba(255, 229, 85, 0.3);
    border-radius: 0px 0px 10px 10px;
}
.TIPwaitListTable2 .TIPwaitListTableRow_h{
    width: 100%;
    height: 25px;
    margin-top: 2px;
    background-color: #008080;
    display: flex;
}
.TIPwaitListTable2 .TIPwaitListTableRow_h .TIPwaitListTableCol1{
    width: 50%;
    height: 25px;
    float: left;
    color: #ffe555;
}
.TIPwaitListTable2 .TIPwaitListTableRow_h .TIPwaitListTableCol2{
    width: 50%;
    height: 25px;
    float: right;
    color: #ffe555;
}
.TIPwaitListTable2 .TIPwaitListTableRow{
    width: 100%;
    height: 25px;
    display: flex;
}
.TIPwaitListTable2 .TIPwaitListTableRow .TIPwaitListTableCol1{
    width: 50%;
    height: 25px;
    float: left;
    color: #ffffff;
}
.TIPwaitListTable2 .TIPwaitListTableRow .TIPwaitListTableCol2{
    width: 50%;
    height: 25px;
    float: right;
    color: #ffffff;
}