.waitForRebuy{
    width:450px;
    height:310px;
    background-color: #0a363c;
    border: 2px solid rgba(255, 229, 85, 0.5);
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}
.waitForRebuy .header{
    width: 100%;
    height: 25px;
    background-image: url("../../../assets/images/popUp/popUp_label.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;
    top: 0;
    left: -10px;
    padding-left: 20px;
}
.waitForRebuy .dataContainer{
    width:96%;
    height: 165px;
    background-color: #091d29;
    margin-left: 2%;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    color: #ffe555;
    text-indent: 10px;
    padding-top: 10px;
    /* display: flex; */
}
.waitForRebuy .dataContainer .line{
    height: 30px;
}
.waitForRebuy .dataContainer .alignThemRight{
    width:80px;  
    position: relative;
    float: right;
    margin-left: auto;
    margin-right: 10px;
    color:white;
}
.waitForRebuy .footer{
    width: 100%;
    height: 35px;
    background-color: #091d29;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    /* position: relative; */
    /* display: flex; */
}
.waitForRebuy .rebuyCountDown{
    width: 300px;
    height: 30px;
    /* border: 1px solid red; */
    position: relative;
    float: left;
    padding-top: 5px;
    padding-left: 10px;
    color: #ffe555;

}

.waitForRebuy .footer button{
    width:70px;
    height: 28px;
    background-color: #ffe555;
    border: none;
    outline: none;
    border-radius: 5px 0px 5px 0px;
    position: relative;
    float: right;
    left: auto;
    margin-right: 10px;
    margin-top: 3px;
}
.waitForRebuy .footer button:hover{
    background-color: #ffe111;
}

.waitForRebuy .textContainer{
    width:96%;
    height: 60px;
    background-color: #091d29;
    margin-left: 2%;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    text-align: center;
    color: #ffe555;
}