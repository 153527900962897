.main-game {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.main-game .hide_cash_table {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.main-game .hide_main_lobby {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

.main-game .hide_tourney_lobby {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

.main-game .cash_table {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.main-game .main_lobby {
  /* position: fixed;
  inset: 0;
  display: flex; */
  /* background-image: url(./../assets/images/lobby/background/bg_lobby.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  object-fit: contain; */
}

.main-game .tourney_lobby {
  /* width: 100%; */
  /* height: calc(100% - 60px); */
  /* width: 100%; */
  /* height: calc(100% - 60px); */
  position: fixed;
  /* inset: 0; */
  display: flex;
  /* background-image: url(/static/media/bg_lobby.4c50c666.png); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  object-fit: contain;
  /* border: 1px solid red; */
  /* left: 0px; */
  /* top: 50px; */
  /* padding: 15px 5px 100px; */
  /* overflow: auto; */
  /* left: 20px; */
  left: 0px;
  right: 0;
  bottom: 0;
  top: 70px;
  /* z-index: 1; */
  /* border: 1px solid red; */
  /* left: 0px; */
  /* top: 50px; */
  /* padding: 15px 5px 100px; */
  /* overflow: auto; */

}

/* Navigation buttons*/
.main-game .poker-nav {
  /* width: calc(75% - 6px); */
  width: 75%;
  height: 70px;
  /* border: 1px solid red; */
  display: flex;
  position: absolute;
  left: 3px;
  /* top: 3px; */
}

.main-game .poker-nav .left {
  width: 75px;
  height: 100%;
  display: grid;

  border-right: 1px solid #707070;
}

.main-game .poker-nav .left1 {
  /* width: 75px; */
  height: 100%;
  display: grid;
  align-items: center;
  border-right: 1px solid #707070;
  padding-right: 10px;
}

.main-game .poker-nav .right {
  /* max-width: 91%; */
  /* min-width: 60px; */
  width: fit-content;
  /* height: -moz-fit-content; */
  /* height: fit-content; */
  height: auto;
  /* padding: 3px 5px; */
  border-radius: 10px;
  box-shadow: inset -40px 0 30px -30px rgba(0, 0, 0, 0.9), inset 40px 0 30px -30px rgba(0, 0, 0, 0.9);
  /* border: 1px solid hsla(0, 0%, 50.2%, 0.1411764705882353); */
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
  overflow: auto;
  /* border: 1px solid; */
}

.next-div {
  display: flex;
  flex-direction: row;
  /* overflow: hidden; */
  /* overflow: scroll; */
  justify-content: center;
  align-items: center;
}

.main-game .poker-nav .right .table-tab {
  background-image: url('../assets/images/tableandcarpet/icon_tables_selector_table.png') !important;
  width: 120px;
  height: 70%;
  background-size: cover;
  /* cursor: pointer; */
  border: none;
  border-radius: 5%;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #ff000000;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.main-game .poker-nav .right .tt-active {
  background-image: url('../assets/images/tableandcarpet/icon_tables_selector_table.png') !important;
  width: 120px;
  height: 70%;
  background-size: cover;
  /* cursor: pointer; */
  border: none;
  border-radius: 5%;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #ff000000;
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  margin: 0px 5px;
  filter: drop-shadow(0 0 6px rgb(160, 160, 160)) !important;
}

.main-game .poker-nav .lb-btn {
  height: 35px;
  width: 100%;
  width: 6vw;
  min-width: 80px;
  font-weight: 400;
  /* / cursor: pointer; / */
  /* / border-radius: 50px; / */
  color: #fff;
  /* / margin-top: 5px; / */

  border-radius: 3px;
  background: #e3c54c;
  color: #000;
  border: 1px solid #6C6B6B;
  box-shadow: 2px 2px 9px 2px #03050c;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.main-game .footNav {
  position: fixed;
  bottom: 100px;
  width: 55px;
  background: #395b8740 url(./../assets/images/lobby/tables.svg) no-repeat;
  padding: 3px;
  right: 10px;
  height: 35px;
  border-radius: 50px;
  background-size: 60px;
  background-position: center;
  animation: tableMove 0.5s 5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

@keyframes tableMove {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.promoMain {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000a1;
  z-index: 9;
}






/* -----------------------------------------features nav bar css  start------------------------------------------ */
.main-game .poker-nav1 {
  /* width: calc(100% - 6px); */
  /* height: 60px; */
  /* border: 1px solid red; */
  display: flex;
  position: fixed;
  left: 3px;
  bottom: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  /* top: 3px; */
}

.main-game .poker-nav1 .left {
  width: 75px;
  height: 100%;
  display: grid;

  /* border-right: 1px solid #707070; */
}

.main-game .poker-nav1 .left1 {

  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.main-game .poker-nav1 .left1 button {
  margin-left: 5px;
}

.main-game .poker-nav1 .right {
  max-width: 50%;
  min-width: 60px;
  height: fit-content;
  padding: 3px 5px;
  border-radius: 10px;
  box-shadow: inset -40px 0 30px -30px rgba(0, 0, 0, 0.9), inset 40px 0 30px -30px rgba(0, 0, 0, 0.9);
  /* border: 1px solid hsla(0, 0%, 50.2%, 0.1411764705882353); */
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
  border: 1px solid var(--clr_blue);
}

.main-game .poker-nav1 .right .table-tab {
  min-width: 30px;
  min-height: 20px;
  background-color: rgb(34, 60, 94);
  border-radius: 6px;
  margin: 0 2.3px;
  padding: 4px 2px 0px 2px;
}

.main-game .poker-nav1 .right .tt-active {
  background-color: #c98d1f !important;
}

.main-game .poker-nav1 .lb-btn:active {
  transform: scale(.96);
}

.main-game .poker-nav1 .lb-btn {
  height: 40px;
  width: 80px;
  font-weight: 400;
  /* cursor: pointer; */
  border-radius: 50px;
  /* background: #161A1D; */
  background: #3c5dc2;
  color: #fff;
  border: 1px solid #6c6b6b;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.45) inset;
  /* box-shadow: 2px 2px 9px 2px #CD2218; */
  border: 1px solid var(--clr_blue);
  margin-top: 5px;
  transform: scale(1);
  transition: all .15s;
}

/* -----------------------------------------features nav bar css  end  ------------------------------------------ */



.tablespage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  row-gap: 5px;
  column-gap: 5px;
}

.timeLeftSpan {
  padding: 3px;
  align-items: center;
  background: #c98d1f;
  border-radius: 50%;
  color: #000;
  position: absolute;
  z-index: 1;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 10px; */

}



.main_lobby,
.hide_main_lobby,
.tourney_lobby,
.hide_tourney_lobby {
  position: relative;
  /* Ensure position is set */
}

.main_lobby {
  z-index: 1;
  /* Base z-index for main lobby */
}

.tourney_lobby,
.hide_tourney_lobby {
  display: none;
  /* Hide by default */
  position: absolute;
  /* Position absolutely to overlap */
  /* z-index: 2; */
  /* Higher z-index to overlay main lobby */
}

.tourney_lobby {
  display: block;
  /* Show when tourneyLobby is active */
}