 .alert .dataAlert {
     width: 96%;
     height: 110px;
     margin-left: 2%;
     background-color: #091d29;
     margin-top: 5px;
     margin-bottom: 5px;
     border-radius: 3px;
     text-align: center;
     color: #ffe555;
 }

 .alert .footer {
     width: 100%;
     height: 35px;
     background-color: #091d29;
     border-radius: 0px 0px 5px 5px;
 }

 .alert .footer button {
     width: 80px;
     height: 28px;
     background-color: #ffe555;
     border-radius: 5px 0px 5px 0px;
     border: none;
     outline: none;
     position: relative;
     left: 135px;
     top: 5px;
 }

 .alert .footer button:hover {
     background-color: #ffe111;
 }

 .alert_cover {
     background: #000506e0;
     position: fixed;
     left: 0;
     right: 0;
     top: 0;
     bottom: 0;
     z-index: 1100;

 }

 .TournamentAlert {
     max-width: 500px;
     height: -moz-fit-content;
     height: fit-content;
     padding: 10px;
     position: fixed;
     z-index: 1200;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     margin: auto;
     display: flex;
     align-items: center;
     justify-content: center;
 }

 .subTournamentAlert {
     background: #101d30;
     border-radius: 5px;
     color: #fff;
     width: 60%;
     float: left;
     position: relative;
     max-height: 90vh;
     overflow: auto;
     box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
     box-shadow: 0px 0px 31px 5px black;
 }

 .TournamentAlert .subTournamentAlert .head {
     padding: 10px;
     background: linear-gradient(#131D50, #040610);
     margin: 0;
     color: #fff;
     font-size: 14px;
     font-style: italic;
     font-weight: bold;
     width: 100%;
     float: left;
     display: flex;
     justify-content: space-between;
     align-items: center;
 }
 .centercontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    /* background-color: #f0f0f0; */
    
}

 .progress-container {
    position: relative;
    width: 120px;
    height: 120px;
  }
  
  .progress {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: conic-gradient(
      red 0%,
      yellow 25%,
      red 25%,
      black 50%,
      black 90%,
      red 100%
    );
    border: 2px solid black;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    box-shadow:  0 0 5px #ff5e00b6;
    animation: rotate 2s linear infinite;
  }
  
  .inner-circle {
    position: absolute;
    top: 15%;
    left: 15%;
    width: 70%;
    height: 70%;
    background-color: #000;
    border-radius: 50%;
    border: 2px solid black;
    box-shadow: inset 0 0 10px #ff5e00b6;
  }
  
  .percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
    font-weight: bold;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  

 
