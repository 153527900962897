 .myTournaments .header {
   width: 100%;
   height: 25px;
   background-image: url("../../../assets/images/popUp/popUp_label.png");
   background-repeat: no-repeat;
   background-size: 100% 100%;
   overflow: hidden;
   position: relative;
   left: -10px;
   padding-left: 20px;
 }

 .myTournaments .footer {
   width: 100%;
   height: 35px;
   background-color: #091d29;
   border-radius: 0px 0px 5px 5px;
   display: flex;
 }

 .myTournaments .footjoin {
   float: left;
   margin-left: 2%;
   margin-top: 5px;
   width: 80px;
   height: 28px;
   background-color: #fbe555;
   border-top-left-radius: 6px;
   border-bottom-right-radius: 6px;
   border: none;
   outline: none;

 }

 .myTournaments .footclose {
   float: right;
   margin-right: 2%;
   margin-top: 5px;
   margin-left: 270px;
   width: 80px;
   height: 28px;
   background-color: #fbe555;
   border-top-left-radius: 6px;
   border-bottom-right-radius: 6px;
   border: none;
   outline: none;
 }

 .myTournaments .footjoin:hover {
   background-color: #fbe111;
 }

 .myTournaments .footclose:hover {
   background-color: #fbe111;
 }

 .myTournaments #table {
   /* font-family: Arial, Helvetica, sans-serif; */
   border-collapse: collapse;
   width: 100%;
   border: 1px solid #091d29;
   border-collapse: separate;
   border-spacing: 0 3px;
   font-size: 13px;
 }

 .myTournaments #table td,
 #table th {
   border: 1px solid rgba(0, 0, 0, 0.1);

 }

 .myTournaments #table td {
   width: 48.98%;
   color: #fbe555;
 }

 .myTournaments #table tr:nth-child(even) {
   background-color: #142f33;
 }

 .myTournaments #table tr:nth-child(odd) {
   background-color: #0a363c;
 }

 .myTournaments #table tr:hover {
   -ms-transform: scale(1.015);
   -webkit-transform: scale(1.015);
   transform: scale(1.015);
 }

 .myTournaments #table tr {
   width: 96%;
   margin-left: 2%;
   margin-top: 5px;
   display: flex;
   flex-wrap: wrap;
   list-style: none;
   border: solid 1px rgb(255, 229, 85, .2);
   border-radius: 2px;

 }

 .loaderDiv {
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   bottom: 0;
   background: #00000061;
 }