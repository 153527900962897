@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-BoldItalic.ttf') format('truetype');
    font-weight:bold ;
    font-style: italic;
}

@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-BookItalic.ttf') format('truetype');
    font-weight: 350;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-EightItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-ExtraLightItalic.ttf') format('truetype');
    font-weight: 250;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-FourItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-HairItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-Italic.ttf') format('truetype');
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-TwoItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Italic/FiraGO-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
} 












@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-Bold.ttf') format('truetype');
    font-weight:bold ;
    font-style: italic;
}

@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-Book.ttf') format('truetype');
    font-weight: 350;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-Eight.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-ExtraLight.ttf') format('truetype');
    font-weight: 250;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-Four.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-Hair.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-Light.ttf') format('truetype');
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-Medium.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-Two.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
} 
@font-face {
    font-family: 'FiraGO';
    src: url('../assets/FiraGO_TTF_1001/Roman/FiraGO-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
} 