.tourneyPlayerRanking{
    width:350px;
    height:180px;
    background-color: #0a363c;
    border: 2px solid rgba(255, 229, 85, 0.5);
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}
.tourneyPlayerRanking .header{
    width: 100%;
    height: 25px;
    background-image: url("../../../assets/images/popUp/popUp_label.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;
    top: 0;
    left: -10px;
    padding-left: 20px;
}
.tourneyPlayerRanking .dataRanking{
    width: 96%;
    height:110px;
    margin-left: 2%;
    background-color: #091d29;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    text-align: center;
    color: #ffe555;
}
.tourneyPlayerRanking .footer{
    width: 100%;
    height:35px;
    background-color: #091d29;
    border-radius: 0px 0px 5px 5px;
}
.tourneyPlayerRanking .footer button{
    width:80px;
    height:28px;
    background-color: #ffe555;
    border-radius: 5px 0px 5px 0px;
    border: none;
    outline: none;
    position: relative;
    left: 135px;
    top:5px;
}
.tourneyPlayerRanking .footer button:hover{
    background-color: #ffe111;
}