 .mytable .tableBox {
   width: 96%;
   height: 14%;
   border-radius: 5px;
   position: relative;
   left: 6px;
   top: 3%;
   background-color: #142f33;
   border: solid 2px rgb(255, 229, 85, .5);
   overflow-y: auto;
   display: flex;
   flex-direction: column;
   color: #fbe555;
 }

 .mytable .tableBox span {
   color: aliceblue;
 }

 .mytable .tableBox .tableBoxrow1 {
   display: flex;
   flex-direction: row;
   border-bottom: 1px solid rgba(0, 0, 0, 0.3);
 }

 .mytable .tableBox .tableBoxrow1>div {
   width: 50%;
   margin: 2px;
   border-right: 1px solid rgba(0, 0, 0, 0.3);
 }

 .mytable .tableBox .tableBoxrow2 {
   display: flex;
   flex-direction: row;
 }

 .mytable .tableBox .tableBoxrow2>div {
   width: 24.68%;
   border-right: 1px solid rgba(0, 0, 0, 0.3);
 }

 .mytable .outputbox {
   width: 96%;
   height: 45%;
   border-radius: 5px;
   position: relative;
   left: 6px;
   top: 5%;
   background-color: #091d29;
   border: solid 2px rgb(255, 229, 85, .5);
   overflow-y: auto;
 }

 .mytable .chat {
   width: 96%;
   color: azure;
   background-color: #091d29;
   position: relative;
   top: 8%;
   left: 6px;
 }

 .mytable .chat input[type=text] {
   border: 2px solid rgb(255, 229, 85, .5);
   ;
   border-radius: 4px;
   width: 94%;
   position: relative;
   top: 1px;
 }

 .mytable .mytablehead {
   text-align: left;
   background-image: url('../../../assets/images/popUp/popUp_label.png');
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
   left: -5px;
   padding-left: 10px;
   background-size: 380px 22.5px;
   color: rgb(0, 0, 0);
   font-size: 16px;
   font-weight: 500;
 }

 .mytable .footjoin {
   width: 120px;
   height: 28px;
   background-color: #fbe555;
   border-top-left-radius: 6px;
   border-bottom-right-radius: 6px;
   border: none;
 }

 .mytable .footclose {
   position: relative;
   left: 230px;
   width: 80px;
   height: 28px;
   background-color: #fbe555;
   border-top-left-radius: 6px;
   border-bottom-right-radius: 6px;
   border: none;
 }

 .mytable .footjoin:hover {
   background-color: #fbe111;
 }

 .mytable .footclose:hover {
   background-color: #fbe111;
 }

 .mytable #table {
   /* font-family: Arial, Helvetica, sans-serif; */
   border-collapse: collapse;
   width: 100%;
   border: 1px solid #091d29;
   border-collapse: separate;
   border-spacing: 0 3px;
   font-size: 13px;
 }

 .mytable #table td,
 #table th {
   border: 1px solid rgba(0, 0, 0, 0.1);

 }

 .mytable #table td {
   width: 48.98%;
   color: #fbe555;
 }

 .mytable #table tr:nth-child(even) {
   background-color: #142f33;
 }

 .mytable #table tr:nth-child(odd) {
   background-color: #0a363c;
 }

 .mytable #table tr:hover {
   -ms-transform: scale(1.015);
   -webkit-transform: scale(1.015);
   transform: scale(1.015);
 }

 .mytable #table tr {
   width: 96%;
   margin-left: 2%;
   margin-top: 5px;
   display: flex;
   flex-wrap: wrap;
   list-style: none;
   border: solid 1px rgb(255, 229, 85, .2);
   border-radius: 2px;

 }



 .tableInfo_btn_section {
   /* border: 1px solid; */
   display: flex;
   align-items: center;
   justify-content: center;
   height: 35px;
   /* padding: 5px; */

   >button {
     height: 100%;
     width: 100%;
     margin: 0px 2px;
     font-weight: 600;
   }
 }