@media screen and (min-width: 577px) and (max-width: 956px) and (orientation: portrait) {
    .closeTbl {
        width: unset !important;
        height: unset !important;
        margin-left: 0px !important;
    }
}

@media screen and (min-width: 957px) and (max-width: 992px) and (orientation: landscape) {
    .tourney_lobby_continer {
        height: calc(100vh - 140px) !important;
        overflow: auto !important;
    }

    .tourney_lobby_container .tourney_lobby_section_two {
        height: unset !important;
        overflow: unset !important;
        border-bottom: unset !important;
    }
}

@media screen and (min-width: 577px) and (max-width: 956px) and (orientation: landscape) {

    .main-game .poker-nav {
        height: 50px !important;
        width: 60vw !important;
    }

    .statsPanel {
        max-height: 50vh;
        overflow: auto;
    }

    .custom-checkbox label {
        font-size: 12px;
    }

    .profile_image {
        height: 45px !important;
    }

    .chatHide {
        height: 80px !important;
        width: 40% !important;
    }

    .menu_header button {
        width: 100% !important;
    }

    .tourney_lobby_continer {
        height: calc(100vh - 140px) !important;
        overflow: auto !important;
    }

    .tourney_lobby_container .tourney_lobby_section_two {
        height: unset !important;
        overflow: unset !important;
        border-bottom: unset !important;
    }

    form .form-container label {
        color: #00e600;
        display: none !important;
    }

    .buttonGroup button {
        margin: 4px !important;
        min-height: 38px !important;
    }

    .chatContainer {
        height: 75px !important;
    }

    .new_range_slider_ {
        bottom: 28vh !important;
        left: -60px !important;
    }

    .main-game .poker-nav .right .tt-active,
    .main-game .poker-nav .right .table-tab {
        width: 100px !important;
        height: 40px !important;
    }

    .tabletop,
    .leftmenu {
        height: 50px !important;
    }

    .leftmenu div {
        justify-content: space-between !important;
        width: 100% !important;
    }

    .leftmenu div>div img,
    .leftmenu div>div svg {
        height: 25px !important;
        width: 35px !important;
    }

    .closeTbl {
        width: unset !important;
        height: unset !important;
        margin-left: 10px !important;
        font-size: 1rem;
    }

    div.show_menu_popup {
        justify-content: center !important;
    }

    .show_menu_popup .sub_menu {
        width: 19.231vw !important;
    }

    .main-game .poker-nav .lb-btn {
        height: 30px !important;
    }

    .popup_1 {
        transform: scale(0.8);
    }

    .statsPanel {
        min-height: 170px;
        height: 80vh !important;
        overflow: auto;
    }

    .breakTimePopup {
        left: unset !important;
        width: 55% !important;
        margin: auto !important;
        inset: 0 !important;
        align-items: center !important;
        display: flex;
        justify-content: center;
    }

    .join-waiting-container button {
        font-size: 12px !important;
        padding: 5px 10px !important;
    }

    .game_type_filter {
        transform: scale(0.8);
    }

    .header_menu {
        height: 75px !important;
    }

    .GameCheckBox {
        margin: 0 !important;
    }

    .custom-checkbox {
        min-height: 30px !important;
    }
}


@media screen and (min-width: 1201px) and (max-width: 1350px) and (orientation: landscape) {

    /* #lobby_link {
        display: none !important;
    } */

    #lobby_link {
        margin-left: -70px;
    }

    .menu_header {
        margin-left: 12vw !important;
    }

    /* .bbj_div {
        display: block !important;
    } */
}

@media screen and (min-width: 1000px) and (max-width: 1201) and (orientation: landscape) {

    #lobby_link {
        display: none !important;
    }

    .menu_header {
        margin-left: 12vw !important;
        background: #333333 !important;
    }

    /* .bbj_div {
        display: block !important;
    } */
}

@media screen and (min-width: 1201px) and (orientation: landscape) {

    .sort_fliter_mobile_cls,
    .lobby-filter,
    .side-menu,
    .mobile_time {
        display: none !important;
    }

    .min_width_992 {
        display: flex !important;
    }

    .max_width_992 {
        display: none !important;
    }

    .cashier_page_logo {
        max-width: 130px;
        min-width: 80px;
        width: 12vw;
    }

    img.tourneyLobby_logo {
        width: 200px;
    }

    #tourney_left_menu_img {
        display: none !important;
    }
}


@media screen and (min-width: 993px) and (max-width: 1200px) and (orientation: landscape) {

    #lobby_link,
    .sort_fliter_mobile_cls,
    .lobby-top-nav_header,
    .device_media,
    .desktop_dashboard_container,
    .max_width_992 {
        display: none !important;
    }

    .statsPanel {
        max-height: 50vh;
        overflow: auto;
    }

    .chatHide {
        height: 80px !important;
        width: 40% !important;
    }

    .form-container label {
        display: none !important;
    }

    .lobby-left-menu-container {
        display: flex;
        align-items: flex-start;
        opacity: 0;
        transform: translateX(-1000px);
        transition: .5s all ease;
        position: relative;
        z-index: 9;
    }

    .lobby-left-menu-container.showMenu {
        opacity: 1;
        transform: translateX(-0px);
    }

    #tourney_left_menu_img {
        position: fixed;
        left: 0;
        z-index: 9;
    }

    .chatContainer {
        height: 75px !important;
    }

    .tourney_lobby_continer {
        height: calc(100vh - 140px) !important;
        overflow: auto !important;
    }

    .tourney_lobby_container .tourney_lobby_section_two {
        height: unset !important;
        overflow: unset !important;
        border-bottom: unset !important;
    }

    .buttonGroup button {
        margin: 4px !important;
    }

    .new_range_slider_ {
        bottom: 30vh !important;
        left: -60px !important;
    }

    .menu_header {
        margin-left: 0 !important;
        height: 55px !important;
        border-radius: 3px !important;
        padding: 4px 0 !important;
        background: #333333 !important;

        >button {
            font-size: 16px;
            border-radius: 4px;
        }
    }

    .game_type_filter {
        transform: scale(0.9) !important;
    }

    .header_menu img.logo,
    .tourneyLobby_logo {
        width: 14vw;
    }

    .menu_body {
        height: calc(100vh - 150px) !important;
        overflow: auto;
    }

    .cashier_page_logo {
        max-width: 130px;
        min-width: 80px;
        width: 12vw;
    }

    .news_div {
        width: 45vw;
        top: 50px;
    }

    .side-menu img {
        display: block !important;
    }

    .min_width_992 {
        display: flex !important;
    }

    .lobby_main_media_tab_land {
        z-index: unset;
    }

    .mainGrid {
        height: calc(100vh - 150px) !important;
    }

    .header_menu {
        >div b {
            margin-bottom: unset !important;
            font-size: 15px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            border: 1px solid var(--border_river) !important;
            background: #00000063 !important;
            padding: 2px 5px !important;
            border-radius: 5px !important;
            margin-top: -15px;
            margin-right: 12px;
        }
    }
}



@media screen and (min-width: 798px) and (max-width: 992px) and (orientation: landscape) {
    .news_div {
        display: none;
    }
}

@media screen and (min-width: 798px) and (max-width: 992px) {

    #lobby_link,
    .sort_fliter_mobile_cls,
    .lobby-top-nav_header,
    .device_media,
    .desktop_dashboard_container,
    .hide_empty_upC_div,
    #mainGrid_table_rv thead div.table_header_row,
    .min_width_992 {
        display: none !important;
    }

    .menu_header {
        margin-left: 0 !important;
        height: 55px !important;
        border-radius: 3px !important;
        padding: 4px 0 !important;
        background: #333333 !important;

        >button {
            font-size: 16px;
        }
    }

    .game_type_filter {
        transform: scale(0.9) !important;
    }

    .menu_header button {
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }

    .header_menu img.logo,
    .tourneyLobby_logo {
        width: 16vw;
    }

    .cashier_page_logo {
        max-width: 130px;
        min-width: 80px;
        width: 12vw;
    }

    .news_div {
        width: 55vw;
        top: 50px;
    }

    .side-menu img {
        display: block !important;
    }

    .lobby_main_media_tab_land {
        z-index: unset;
    }

    .mainGrid {
        height: calc(100vh - 150px) !important;
    }

    .menu_body {
        height: calc(100vh - 140px) !important;
        overflow: auto;
    }

    .main-game .tourney_lobby {
        left: 20px;
    }

    .lobby-top-nav {
        margin: 0px 10px;
    }

    .avatar_section {
        max-height: 220px;
    }

    .limi_filter_media_ {
        display: block !important;
    }

    .max_width_992 {
        display: flex !important;
    }

    #mainGrid_table_rv tbody tr td {
        width: 100%;
        height: 100%;
        display: block;
        float: none;
        position: relative;
        padding: 0px 5px !important;
    }

    #mainGrid_table_rv tbody tr td .max_width_992 {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 2px;
    }

    #mainGrid_table_rv tbody tr td .max_width_992 .mobile-lobby-right-div {
        flex-direction: column;
        overflow: hidden;
    }

    #mainGrid_table_rv tbody tr td .max_width_992 .mobile-lobby-left-div {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .lobby-left-menu-container {
        display: flex;
        align-items: flex-start;
        opacity: 0;
        transform: translateX(-1000px);
        transition: .5s all ease;
        position: relative;
        z-index: 9;
    }

    .lobby-left-menu-container.showMenu {
        opacity: 1;
        transform: translateX(-0px);
    }

    #tourney_left_menu_img {
        position: fixed;
        left: 0;
        z-index: 9;
    }

    .header_menu {
        /* height: 50px !important; */
        padding: 0px 10px !important;

        >div b {
            margin-bottom: unset !important;
            font-size: 15px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            border: 1px solid var(--border_river) !important;
            background: #00000063 !important;
            padding: 2px 5px !important;
            border-radius: 5px !important;

            >img {
                width: 25px;
                margin-left: 5px;
            }
        }

        div div img {
            height: 30px !important;
            margin-right: 5px !important;
        }
    }
}



@media screen and (min-width: 577px) and (max-width: 797px) {

    #lobby_link {
        display: flex !important;

        >img {
            width: 80px !important;
            height: 40px !important;
        }
    }

    .sort_fliter_mobile_cls,
    .device_media,
    .news_div,
    .limi_filter_media_,
    .mobile_time,
    .cashier_div,
    .min_width_992,
    .desktop_dashboard_container {
        display: none !important;
    }

    .bbj_div {
        >img {
            min-width: 95px !important;
        }
    }

    .bbj_div span {
        top: 12px !important;
        font-size: 13px !important;
    }

    .tourneyLobby_logo {
        width: 20vw;
    }

    .game_type_filter {
        transform: scale(0.9) !important;
    }

    .lobby-top-nav_header,
    #mainGrid_table_rv thead div.table_header_row {
        display: none !important;
    }

    .menu_header {
        margin-left: 0 !important;
        height: 45px !important;
        border-radius: 3px !important;
        background: #333333 !important;
        padding: 4px 0 !important;

        >button {
            font-size: 15px !important;
            border-radius: 4px;
        }
    }

    .menu_header button {
        width: 100% !important;
        height: 100% !important;
    }

    .header_menu {
        height: 50px;
        padding: 0px 10px !important;

        >div b {
            margin-bottom: unset !important;
            font-size: 15px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            border: 1px solid var(--border_river) !important;
            background: #00000063 !important;
            padding: 2px 5px !important;
            border-radius: 5px !important;

            >img {
                width: 25px;
                margin-left: 5px;
            }
        }

        div div img {
            height: 30px !important;
            margin-right: 5px !important;
        }
    }

    .lobby-top-nav {
        height: 50px !important;
        justify-content: space-between !important;
        border-radius: 3px !important;
        margin: 0px 10px !important;
        padding: 0px 0px 0px 6px !important;
    }

    .lobby-top-nav div.time_display {
        width: 80px !important;
        height: 40px !important;
        border-radius: 5px !important;
    }

    .lobby-top-nav div.lobby_active_icon img {
        width: 30px !important;
        margin: 0px 1px !important;
    }

    header.profile_head {
        height: 45px !important;
        font-size: 18px !important;
        padding: 0px 10px !important;
        border-radius: 3px !important;
    }

    .profile_image {
        height: 40px !important;
    }

    .mainGrid {
        height: calc(100vh - 120px) !important;
    }

    .menu_body {
        height: calc(100vh - 95px) !important;
        overflow: auto !important;
    }

    .side-menu img {
        display: block !important;
    }

    .device_media_margin_left {
        margin-left: 20px !important;
    }

    .lobby_main_media_tab_land {
        z-index: unset !important;
        padding: 0px 12px 0px 26px !important;
    }

    .hide_empty_upC_div {
        display: none !important;
    }

    /* .main-game .poker-nav {
        height: 60px !important;
        width: 60vw !important;
    } */

    /* .main-game .poker-nav .right .tt-active,
    .main-game .poker-nav .right .table-tab {
        width: 90px !important;
        height: 60% !important;
    } */

    .main-game .poker-nav .right .tt-active,
    .main-game .poker-nav .right .table-tab {
        width: 100px !important;
        height: 40px !important;
    }

    .chatHide {
        height: 60px !important;
        width: 50% !important;
    }

    .rebuy_table_icon {
        width: 35px !important;
        height: 40px !important;
    }

    .lobby-left-menu-container {
        display: flex;
        align-items: flex-start;
        opacity: 0;
        transform: translateX(-1000px);
        transition: .5s all ease;
        position: relative;
        z-index: 9;
    }

    .lobby-left-menu-container.showMenu {
        opacity: 1;
        transform: translateX(-0px);
    }

    #tourney_left_menu_img {
        position: fixed;
        left: 0;
        z-index: 9;
    }

    .leftmenu,
    .tabletop {
        height: 50px !important;
    }

    .avatar_section {
        max-height: 180px !important;
    }

    .max_width_992 {
        display: flex !important;
    }

    .min_width_992 {
        display: none !important;
    }
}



@media screen and (max-width: 576px) {
    #lobby_link {
        display: none !important;
    }

    .sort_fliter_mobile_cls {
        display: block !important;
    }

    .lobby-top-nav_header {
        display: none !important;
    }

    .tourney_lobby_container .tourney_lobby_section_two {
        height: unset !important;
        overflow: unset !important;
        border-bottom: unset !important;
    }

    .tourney_lobby_continer {
        height: calc(100vh - 100px) !important;
        overflow: auto !important;
    }

    .menu_header {
        margin-left: 0 !important;
        height: 45px !important;
        border-radius: 3px !important;
        background: #333 !important;
        padding: 4px 0 !important;
    }

    .menu_header>button {
        font-size: 15px !important;
    }

    #tourney_left_menu_img {
        position: fixed;
        left: 0;
        z-index: 9;
    }

    .lobby-left-menu-container {
        display: flex;
        align-items: flex-start;
        opacity: 0;
        transform: translateX(-1000px);
        transition: .5s all ease;
        position: relative;
        z-index: 9;
    }

    .lobby-left-menu-container.showMenu {
        opacity: 1;
        transform: translateX(-0px);
    }

    .info_container {
        min-width: unset !important;
        max-height: unset !important;
    }

    .main-game .tourney_lobby {
        /* left: 20px !important; */
        top: 50px !important;
    }

    .menu_header button {
        width: 100% !important;
        height: 100% !important;
        border-radius: 4px;
    }

    .cashier_div {
        display: none !important;
    }

    .tourneyLobby_logo {
        width: unset !important;
        height: 40px !important;
    }

    .header_menu img.logo {
        width: unset !important;
        height: 40px !important;
    }

    #mainGrid_table_rv thead div.table_header_row {
        display: none !important;
    }

    .header_menu img.menu {
        width: unset !important;
        height: 25px !important;
    }

    .device_media {
        display: none !important;
    }

    .news_div {
        display: none !important;
    }

    .device_media_margin_left {
        margin-left: 20px !important;
    }

    .side-menu img {
        display: block !important;
    }

    .desktop_dashboard_container {
        display: none !important;
    }

    .lobby_main_media_tab_land {
        z-index: unset !important;
        padding: 0 10px !important;
    }

    .hide_empty_upC_div {
        display: none !important;
    }

    .header_menu {
        height: 50px !important;
        padding: 0 10px 0 0 !important;
    }

    .header_menu>div b {
        margin-bottom: unset;
        font-size: 15px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--border_river);
        background: #00000063;
        padding: 2px 5px;
        border-radius: 5px;
    }

    .header_menu div div img {
        height: 30px !important;
        margin-right: 5px;
    }

    .lobby-top-nav {
        height: 50px !important;
        justify-content: space-between !important;
        border-radius: 3px !important;
        margin: 0 2px 0 10px !important;
        padding: 0 0 0 6px !important;
    }

    .lobby-top-nav div.time_display {
        width: 80px !important;
        height: 40px !important;
        border-radius: 5px !important;
    }

    .lobby-top-nav div.lobby_active_icon img {
        width: 30px !important;
        margin: 0 5px !important;
    }

    header.profile_head {
        height: 45px !important;
        font-size: 18px !important;
        padding: 0 10px !important;
        border-radius: 3px !important;
    }

    .limi_filter_media_ {
        display: none !important;
    }

    .profile_image {
        height: 40px !important;
    }

    .mainGrid {
        height: calc(100vh - 120px) !important;
    }

    .menu_body {
        height: calc(100vh - 95px) !important;
        overflow: auto !important;
    }

    nav.lobby-top-nav div#lobby_link img {
        margin: 0 2px !important;
    }

    .lobby-top-nav div img:hover {
        cursor: default !important;
        filter: unset !important;
        transform: unset !important;
    }

    .show_menu_popup .sub_menu .options {
        height: unset !important;
    }

    .min_width_992 {
        display: none !important;
    }

    .max_width_992 {
        display: flex !important;
    }

    .main-game .poker-nav {
        height: 60px !important;
    }

    .main-game .poker-nav .right .tt-active {
        width: 90px !important;
        height: 60% !important;
    }

    .main-game .poker-nav .right .table-tab {
        width: 90px !important;
        height: 60% !important;
    }

    .chatHide {
        height: 60px !important;
        width: 50% !important;
    }

    .rebuy_table_icon {
        width: 35px !important;
        height: 40px !important;
    }

    .leftmenu {
        height: 60px !important;
    }

    .leftmenu>div.show_menu_popup {
        justify-content: center !important;
    }

    .tabletop {
        height: 60px !important;
    }

    .avatar_section {
        max-height: 180px !important;
    }

    .bbj_div {
        display: none !important;
    }

    .join-waiting-container button {
        font-size: 12px !important;
        padding: 5px 10px !important;
    }

    .game_type_filter {
        transform: scale(0.9) !important;
    }

    .breakTimePopup {
        position: fixed;
        left: unset !important;
        width: unset !important;
        visibility: visible !important;
        inset: 0 !important;
        display: flex !important;
        align-items: center !important;
        margin: auto !important;
        justify-content: center !important;
    }

    .addonPopup {
        position: fixed !important;
        inset: 0 !important;
        overflow: hidden !important;
        height: 84px !important;
        margin: auto !important;
        background-position: bottom !important;
        background-repeat: no-repeat !important;
        width: 78% !important;
        background-image: url('../../assets/images/table/HeadIcons/message_label_bg.png') !important;
        background-size: unset !important;
        padding: unset !important;
        transform: unset !important;
    }

    .addonPopup>div.sprite1 {
        width: 50px !important;
    }

    .side-menu img {
        height: 70px !important;
    }
}


@media screen and (max-width: 992px) and (orientation: portrait) {
    .login-container {
        flex-direction: column !important;
        height: 100vh !important;
        align-items: center;
        justify-content: center;
    }

    .login-container>section {
        width: 100% !important;
        max-width: 550px;
        height: unset !important;
        margin: unset !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    main.login-container section form div.form-container {
        width: 100% !important;
        /* padding: 0px 35px !important; */
        padding: 0px 10vw !important;
    }

    .login-input-fields .captch-text {
        padding: 2px !important;
    }

    .login-input-fields {
        width: 100% !important;
    }

    .login-container>section>form {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .login-container>section>form>.login-btn {
        margin: 0 !important;
    }

    main.login-container header {
        top: 0 !important;
        margin-top: 15px !important;
    }

    main.login-container header>span {
        font-size: 18px !important;
    }

    main.login-container section span {
        min-width: 60px !important;
    }

    .gameController {
        width: 100% !important;
    }

    .riseOptions {
        width: 200px !important;
    }

    .checkboxGroup {
        width: 100% !important;
    }

    .s-thumb {
        bottom: -14px !important;
    }

    .s-wrapper {
        left: -40px !important;
    }
}

@media (max-width: 1200px) {
    .tourney-lobby-section-container {
        flex-direction: column;
    }

    .tourney_lobby_container .tourney_lobby_section_two {
        border-right: none;
        border-bottom: 1px solid gray;
    }
}



.rebuy_table_icon {
    width: 40px;
    height: 45px;
}

.bbj_div {
    position: relative;
    height: 45px;
    margin-right: 10px;
    /* width: 118px; */

    >img {
        height: 100%;
        width: 7.372vw !important;
        min-width: 105px !important;
    }

    span {
        position: absolute;
        right: 6px;
        top: 8px;
        color: #000;
        font-size: 15px;
        font-weight: 500;
    }
}

.desktop_dashboard_container {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.chatContainer.chatMinimize {
    height: 150px;
}

.chatContainer.chatMaximize {
    height: calc(100% - 58px) !important;
}

.desktop_dashboard {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border_river);
    background: #666666;
    /* background: #959595; */
    /* background: #00000054; */
    /* background: #fff; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px inset, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px inset, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */

    >.container {
        position: relative;
    }

    .clock {
        width: 58px;
        height: 58px;
        /* border: 2px solid rgba(0, 0, 0, .25); */
        border-radius: 50%;
        /* background-color: rgba(0, 0, 0, .1); */
        /* box-shadow: 0px 0px 30px rgba(0, 0, 0, .5); */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .clock span {
        position: absolute;
        transform: rotate(calc(30deg * var(--i)));
        inset: 12px;
        text-align: center;
        font-size: 5px;
        font-weight: 900 !important;
        font-family: sans-serif;
    }

    .clock span b {
        display: inline-block;
        transform: rotate(calc(-30deg * var(--i)));
        color: #000;
    }

    .clock::before {
        content: "";
        position: absolute;
        width: 3px;
        height: 3px;
        background: #ffff;
        border-radius: 50%;
        z-index: 1;
    }

    .hand {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .hand i {
        position: absolute;
        width: 1px;
        height: var(--h);
        background-color: var(--clr);
        border-radius: 8px;
    }

}

.dashboard_table_details,
.dashboard_player_details {
    position: relative;
    height: 100%;
    align-items: center;
    /* width: 100%; */
    padding: 0px 5px 0px 0px;

    >.container {
        position: relative;
    }
}

.top_time_dashboard {

    >b {
        /* color: rgba(0, 0, 0, 0.6); */
        color: rgb(0, 0, 0);
        text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.1);
        position: relative;
    }
}




@keyframes opacity-animation {
    0% {
        opacity: 0
    }
}

@keyframes pulse-animation {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: .8
    }

    70% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(3)
    }

    to {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0
    }
}

.pulse:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: #f9e9a2;
    border-radius: 100%;
    opacity: .5;
    transform: translate(-50%, -50%) scale(3);
    animation: pulse-animation 3s infinite;
}

.pulse {
    position: relative;
    display: inline-block;
    vertical-align: 1px;
    width: 8px;
    height: 8px;
    margin: 0 6px;
    background: #ffdd40;
    color: transparent;
    border-radius: 100%;
    flex: 0 0 auto;
    animation: opacity-animation 1s linear;
    /* position: absolute;
    right: 10px;
    top: 0px; */

    &.red {
        background: #e60000;
    }

    &.orange {
        background: #ff9900;
    }

    &.green {
        background: #00e600;
    }
}

.dashboard_profile_img {
    border: 2px solid rgba(0, 0, 0, .1);
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .1);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, .5);
    position: relative;
    margin-top: 42px;
    /* height: 100%;
    width: 100%; */
}


.dashboard_stars {
    position: absolute;
    width: 100%;

    >div img {
        width: 15px;
    }
}

.dashboard_stars div:nth-child(1) {
    position: absolute;
    /* border: 1px solid red; */
    left: 2px;
    top: 16px;

    >img {
        width: 10px;
    }
}

.dashboard_stars div:nth-child(2) {
    position: absolute;
    /* border: 1px solid red; */
    left: 10px;
    top: 2px;

    >img {
        width: 12px;
    }
}

.dashboard_stars div:nth-child(3) {
    position: absolute;
    /* border: 1px solid red; */
    right: 0px;
    left: 0px;
    top: 0px;
    margin: auto;
    text-align: center;
    margin-top: -4px !important;

    >img {
        width: 14px;
    }
}

.dashboard_stars div:nth-child(4) {
    position: absolute;
    /* border: 1px solid red; */
    right: 10px;
    top: 2px;

    >img {
        width: 12px;
    }
}

.dashboard_stars div:nth-child(5) {
    position: absolute;
    /* border: 1px solid red; */
    right: 2px;
    top: 16px;

    >img {
        width: 10px;
    }
}

.chenge_avatar_btn {
    height: 35px;
    border: none;
    font-weight: 600;
    cursor: pointer;
}

.profile_sections_lobby {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2px;
    /* color: #fff; */
    font-size: 1rem;
    /* margin-bottom: 26px;
    margin-right: 30px; */
    margin-right: 5px;
}

.profile_sections_lobby span:nth-child(2) {
    background: rgba(0, 0, 0, 0.456);
    width: fit-content;
    padding: 0px 5px;
    border-radius: 2px;
    margin-top: 3px;
    display: flex;
}

.addScreen {
    background: #00000052;
    padding: 2px 5px;
    border-radius: 5px;

    >span {
        font-weight: 600;
    }
}


.overflow_ellips {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: fit-content;
}

.avtar_change_btn {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -6px;
    font-size: 12px;
    cursor: pointer;
    z-index: 1;
}

.news-marquee {
    display: inline-block;
    white-space: nowrap;
    /* overflow: hidden; */
    width: 100%;
    /* width: 33vw; */
    animation: scroll-left 10s linear infinite;
    color: #cdcdcd;
}

.news-marquee span {
    display: inline-block;
    padding-right: 2rem;
}

@keyframes scroll-left {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}






.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    z-index: 1;
}

.tooltip-text {
    position: absolute;
    bottom: 130%;
    /* Positions tooltip above the text */
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
}

.tooltip-container:hover .tooltip-text,
.tooltip-container .tooltip-text.visible {
    opacity: 1;
    visibility: visible;
}

.lobby-menu-icon {
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lobby-menu-icon.Menu_Avtive {
    background: linear-gradient(rgb(254, 214, 38), rgb(142, 117, 8)) !important;
}

.lobby-menu-icon img {
    filter: drop-shadow(0px 1px 2px black);
}

/* .eyehover>div:hover {
    cursor: pointer;
    filter: drop-shadow(0.5rem 0.2rem 2.5rem black);
    transform: scale(1.2);
} */

/* .filterhover:hover{
        cursor: pointer;
        filter: drop-shadow(0.5rem 0.2rem 2.5rem black);
        transform: scale(1.1);
    } */
/* .mousehover>div:hover {
    cursor: pointer;
    filter: drop-shadow(0.5rem 0.2rem 2.5rem black);
    transform: scale(1.1);
}

.mousehover>img:hover {
    cursor: pointer;
    filter: drop-shadow(0.5rem 0.2rem 2.5rem black);
    transform: scale(1.1);
} */