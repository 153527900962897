.tableDetails {
  width: 100%;
  float: left;
}

.tableDetails .containerOne {
  /* font-family: 'Arial Narrow', Arial, sans-serif; */
  width: 100%;
  height: 38vh;
  float: left;
  border: 1px rgba(92, 90, 83, 0.3) solid;
  border-radius: 3px;
  overflow-x: hidden;
  position: relative;
}

.tableDetails .containerTwo {
  border: 1px rgba(92, 90, 83, 0.3) solid;
  border-radius: 3px;
  padding: 10px 0px;

}

.tableDetails .containerTwo .row {
  display: flex;
  flex-direction: row;
}

.tableDetails .containerThree {
  width: 98%;
  height: 20px;
  background-color: #0e5c63;
  color: #070b13;
  text-align: center;
}

.tableDetails .tableDetailsTabsContainer img {
  width: 15px;
  cursor: pointer;
}

.tableDetails .tableDetailsTabsContainer {
  width: 30px;
  height: 25px;
  /* border: 1px solid green; */
  z-index: 3;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.tableDetails .containerOne .tablePreviewTab {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: none;
}

.tableDetails .containerOne .tablePreviewTab_show {
  display: block;
}

.tableDetails .containerOne .tableDetailsTab {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: none;
}

.tableDetails .containerOne .tableDetailsTab_show {
  display: block;
}




#tableDetails_table {
  /* font-family: 'Arial Narrow', Arial, sans-serif; */
  border-collapse: collapse;
  width: 100%;
  overflow-x: hidden;
}

#tableDetails_table td {
  /* border: 1px solid #000000; */
  /* background-color: #0a3236; */
  color: #ddd;
  height: 20px;
  /* padding-left: 2px; */
  text-indent: 5px;
}

#tableDetails_table tr:nth-child(odd) {
  background-color: #062326;
}

#tableDetails_table th {
  /* padding-top: 12px; */
  height: 25px;
  /* padding-bottom: 12px; */
  text-align: center;
  background-color: #0e5c63;
  color: #ffe555;
  border: 1px solid #000000;
  position: sticky;
  top: 0px;
}

.tableDetails .containerTwo button {
  width: 100%;
  border: 1px solid #dbdbdb;
  /* display: block; */
  outline: none;
  cursor: pointer;
  background: var(--grade_2);
  padding: 8px;
  border: none;
  color: #000;
  font-weight: bold;
  margin-top: 0px;
}

.tableDetails .containerTwo button:hover {
  background-color: #ffe555;
}

/* .tableDetails .containerTwo .row{
  display: flex;
  flex-direction: row;
} */



/* ---------------------------------------------------------------------------------------------------------------- */



.preview-container {
  position: absolute;
  display: grid;
  grid-template-rows: 0.5fr 1fr;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin !important;
}

.preivew-head-section {
  /* border: 1px solid; */
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  /* border: 1px solid #4d4d4d; */
  display: flex;
  flex-direction: column;
  /* border-top: none; */
  /* justify-content: center; */
  border-bottom: 1px solid var(--border_river);
}

.preview-head-btns-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* position: relative; */
  min-height: 50px;
  /* background-image: linear-gradient(rgb(151 151 151 / 79%), rgba(0, 0, 0, 0.747)); */
  border-bottom: 1px solid var(--border_river);
  position: sticky !important;
  top: 0 !important;
  background: #0d1219;
  z-index: 1;


  >button {
    height: 30px;
    cursor: pointer;
    border-radius: 4px;
  }
}

.preview-sub-head-div {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  padding: 10px 10px 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  >div {
    height: 100%;
  }
}

.preview-sub-head-div div:last-child {
  border: none;

  /* >span:last-child {
    color: var(--clr_river);
  } */
}

/* .tableDetailsHeadSec span:last-child {
  color: var(--clr_river);
} */

.preview-sub-head-sub-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #4d4d4d;
  padding-top: 5px;
}

.preview-table-section {
  min-width: 344px;
  min-height: 240px;
  height: 100%;
  border-bottom: 1px solid var(--border_river);
  background: radial-gradient(#131d509e, #0d1218);
  overflow: auto;
}

.preview-footer-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 8px 0px;

  >button {
    min-height: 30px;
    width: 30%;
    font-weight: 600;
    cursor: pointer;
    white-space: normal;
    text-align: center;
    /* color: rgb(212, 49, 49);
    border-radius: 4px;
    background-image: linear-gradient(#fff, #7d7c7c);
    box-shadow: 3px 2px 2px 0px rgb(212, 49, 49);
    transition: all .15s; */
  }
}

.preview-footer-section button:active {
  /* transform: scale(0.95); */
  box-shadow: none;
  transform: translateY(4px);
}

.river_border {
  border: 1px solid #4d4d4d;
}



.InfoTable th,
.InfoTable td {
  border: 1px solid #4d4d4d;
  padding: 8px;
}


.tourneyDescription_text {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 600;
  opacity: .4;
}

/* .buttonhover
>button:hover{
  cursor: pointer;
  filter: drop-shadow(0.5rem 0.2rem 2.5rem black);
  transform: scale(1.1);
  border-radius: 4px;
} */





.tableDetailsHeadSec {
  margin: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  padding: 2% 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) -2px -3px 0px inset;
  background: radial-gradient(circle, #1f2a39 0%, #101010 100%);

  >span {
    /* color: #000 !important; */
    font-weight: 500;
  }
}

/* .preview-head-btns-div button:hover {
  transform: translateY(-4px);
  transition: all .15s;
} */

.preview-head-btns-div {

  .bgblueSet,
  .bgredSet {
    box-shadow: 3px 3px 1px 0px black;
    color: #fff;
  }

  .bgblueSet {
    background-image: linear-gradient(rgb(68 201 191), rgb(18 83 87));
  }

  .bgredSet {
    background-image: linear-gradient(rgb(233 80 51), rgb(127 23 20));
  }
}

.bgredSet {
  /* background-image: linear-gradient(rgb(127 23 20), rgb(233 80 51)); */
  background-image: linear-gradient(to bottom, #e5e5e5 0%, #d1d1d1 50%, #c4c4c4 50%, #b8b8b8 100%);
}

.bgblueSet {
  /* background-image: linear-gradient(rgb(18 83 87), rgb(68 201 191)); */
  background-image: linear-gradient(to bottom, #e5e5e5 0%, #d1d1d1 50%, #c4c4c4 50%, #b8b8b8 100%);
}

.title_border {
  border-image: linear-gradient(to right, #E1D45B 0%, #FA8D46 100%) 1;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}


.button-30 {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 4.144vh;
  max-height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding: 0px 5px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s, transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 14px;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}